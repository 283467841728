import { AppComponent } from './app.component';
import { RootReducer, Store } from './app.reducers';
import { CoreModule } from './core/core.module';
import { DialogModule } from './dialog/dialog.module';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { ContentAccessGuardService } from './shared/services/content-access-guard.service';
import { NewFeatureGuardService } from './shared/services/new-feature-guard.service';
import { PunchOutGuardService } from './shared/services/punch-out-guard.service';
import { DATE_PIPE_DEFAULT_OPTIONS, DecimalPipe } from '@angular/common';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/fi';
import '@angular/common/locales/global/sv';
import { ErrorHandler, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { rootRouting } from '@app/app.router';
import { AppInitializerModule } from '@app/shared/modules/app-initializer.module';
import { LoggingErrorHandler } from '@app/shared/services/logging-error-handler.service';
import { NewRelicProvider } from '@app/shared/services/newrelic.service';
import { ResolveStoreService } from '@app/shared/services/resolve-store.service';
import { SharedModule } from '@app/shared/shared.module';
// eslint-disable-next-line no-restricted-imports
import { StoreModule } from '@ngrx/store';
// TODO: Import and enable dynamically based on environment, and possibly user preference (e.g. local storage?).
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@src/environments/environment';

const providers: Provider[] = [
	AuthGuardService,
	ContentAccessGuardService,
	NewFeatureGuardService,
	NewRelicProvider,
	PunchOutGuardService,
	ResolveStoreService,
	Store,
	{ provide: ErrorHandler, useClass: LoggingErrorHandler },
	{ provide: 'Window', useValue: window },
	{
		provide: DATE_PIPE_DEFAULT_OPTIONS,
		useValue: {
			dateFormat: $localize`:https\://angular.io/api/common/DatePipe#usage-notes@@DateFormat:dd/MM/yyyy`,
		},
	},
	DecimalPipe,
];

// In local dev environment override locale id, in production it's included in the build.
if (environment.envName === 'local') {
	providers.push({ provide: LOCALE_ID, useValue: 'en' });
}

/** App root module */
@NgModule({
	imports: [
		AppInitializerModule,
		rootRouting,
		StoreModule.forRoot(RootReducer.reducers, {
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictActionTypeUniqueness: true,
				strictActionWithinNgZone: true,
				strictStateSerializability: false,
				strictActionSerializability: false,
			},
		}),
		// environment.enableStoreDevtools ? StoreDevtoolsModule.instrument() : [],
		BrowserModule,
		BrowserAnimationsModule,
		CoreModule,
		SharedModule,
		DialogModule,
		RouterModule,
	],
	providers,
	declarations: [AppComponent],
	bootstrap: [AppComponent],
})
export class AppModule {}
