// @ts-nocheck
/// <reference path="types.d.ts"/>
/** @module Product */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway';

/**
 * Get Products by store id
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {string} [options.type] Enum: variable, stock, print-on-demand, form, multiple-option. 
 * @param {string} [options.sortBy] Enum: name, created_at, sku. Sort results by this
 * @param {number} [options.sortDir] Sort direction, 1 for asc, -1 for desc
 * @param {string} [options.sortLang] Enum: en, fi, sv. Sort language
 * @param {number} [options.page] Which page to get
 * @param {number} [options.pageSize] How many products per page
 * @return {Promise<module:types.ProductDto[]>} OK
 */
export function getProducts(options?: GetProductsOptions): Promise<api.Response<api.ProductDto[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      type: options.type,
      sortBy: options.sortBy,
      sortDir: options.sortDir,
      sortLang: options.sortLang,
      page: options.page,
      pageSize: options.pageSize
    }
  };
  return gateway.request(getProductsOperation, parameters);
}

/**
 * Get Products by store and product IDs
 * 
 * @param {string} productIds 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {string} [options.sortBy] Enum: name, created_at, sku. Sort results by this
 * @param {number} [options.sortDir] Sort direction, 1 for asc, -1 for desc
 * @param {string} [options.sortLang] Enum: en, fi, sv. Sort language
 * @return {Promise<object>} OK
 */
export function getProductsByIdList(productIds: string, options?: GetProductsByIdListOptions): Promise<api.Response<{
    models?: api.ProductDto[]
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      productIds,
      sortBy: options.sortBy,
      sortDir: options.sortDir,
      sortLang: options.sortLang
    }
  };
  return gateway.request(getProductsByIdListOperation, parameters);
}

/**
 * Get Products by store id and product category
 * 
 * @param {string} categoryIds 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {string} [options.sortBy] Enum: name, created_at, sku. Sort results by this
 * @param {number} [options.sortDir] Sort direction, 1 for asc, -1 for desc
 * @param {string} [options.sortLang] Enum: en, fi, sv. Sort language
 * @param {number} [options.page] Which page to get
 * @param {number} [options.pageSize] How many products per page
 * @return {Promise<object>} OK
 */
export function getProductsByCategory(categoryIds: string, options?: GetProductsByCategoryOptions): Promise<api.Response<{
    models?: api.ProductDto[]
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      categoryIds,
      sortBy: options.sortBy,
      sortDir: options.sortDir,
      sortLang: options.sortLang,
      page: options.page,
      pageSize: options.pageSize
    }
  };
  return gateway.request(getProductsByCategoryOperation, parameters);
}

/**
 * Get product storage information
 * 
 * @param {string} productIds 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<object[]>} OK
 */
export function getProductInventoryByIdList(productIds: string, options?: GetProductInventoryByIdListOptions): Promise<api.Response<{
    id?: number,
    product?: api.ProductDtoDto,
    stock_level?: number,
    storage_warning_level?: number,
    storage_type?: string,
    storage_warning_level_emails?: string,
    warning_email_sent_at?: string,
    reserved_quantity?: number
  }[]>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      productIds
    }
  };
  return gateway.request(getProductInventoryByIdListOperation, parameters);
}

/**
 * Update product storage information
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<object>} OK
 */
export function updateProductInventory(id: number, options?: UpdateProductInventoryOptions): Promise<api.Response<{
    success?: boolean
  }>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateProductInventoryOperation, parameters);
}

/**
 * Get a Product by id
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.ProductDto>} OK
 */
export function one(id: number, options?: OneOptions): Promise<api.Response<api.ProductDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    }
  };
  return gateway.request(oneOperation, parameters);
}

/**
 * Update product with provided information
 * 
 * @param {number} id 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @param {object} [options.body] 
 * @return {Promise<module:types.ProductDto>} OK
 */
export function update(id: number, options?: UpdateOptions): Promise<api.Response<api.ProductDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      id
    },
    body: {
      body: options.body
    }
  };
  return gateway.request(updateOperation, parameters);
}

/**
 * Destroy Products from database.
 * 
 * @param {number} id 
 * @return {Promise<object>} OK
 */
export function destroy(id: number): Promise<api.Response<undefined>> {
  const parameters: api.OperationParamGroups = {
    path: {
      id
    }
  };
  return gateway.request(destroyOperation, parameters);
}

/**
 * Get a Product by SKU
 * 
 * @param {string} sku 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] Storefront base URL for context e.g. *mystore.mygrano.fi*
 * @return {Promise<module:types.ProductDto>} OK
 */
export function getProductBySku(sku: string, options?: GetProductBySkuOptions): Promise<api.Response<api.ProductDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    path: {
      sku
    }
  };
  return gateway.request(getProductBySkuOperation, parameters);
}

/**
 * Get list of Products from database.
 */
export function all(): Promise<api.Response<api.Product[]>> {
  return gateway.request(allOperation);
}

/**
 * Create new Product in database.
 * 
 * @param {object} options Optional options
 * @param {module:types.Product} [options.body] 
 * @return {Promise<module:types.Product>} OK
 */
export function create(options?: CreateOptions): Promise<api.Response<api.Product>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  };
  return gateway.request(createOperation, parameters);
}

/**
 * Get Product model schema describing the database table.
 */
export function schema(): Promise<api.Response<api.Product>> {
  return gateway.request(schemaOperation);
}

/**
 * Get all products in a store, supports filtering and pagination
 * 
 * @param {object} options Optional options
 * @param {string} [options.XStoreIdentifier] From what store are we searching, by default the Origin header is used
 * @param {string} [options.filters] Filter expression for filtering (https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering)
 * @param {number} [options.page] Page number for pagination. First page is 0.
 * @param {number} [options.pagesize] Page size for pagination. Ignored when used without the page parameter.
 * @param {string} [options.orderby] Sort expression (https://js.devexpress.com/Documentation/18_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/sorting/)
 * @return {Promise<module:types.PaginatedProductsDto>} 
 */
export function getMany(options?: GetManyOptions): Promise<api.Response<api.PaginatedProductsDto>> {
  if (!options) options = {};
  const parameters: api.OperationParamGroups = {
    header: {
      'X-Store-Identifier': options.XStoreIdentifier
    },
    query: {
      filters: options.filters,
      page: options.page,
      pagesize: options.pagesize,
      orderby: options.orderby
    }
  };
  return gateway.request(getManyOperation, parameters);
}

export interface GetProductsOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  type?: 'variable'|'stock'|'print-on-demand'|'form'|'multiple-option';
  /**
   * Sort results by this
   */
  sortBy?: 'name'|'created_at'|'sku';
  /**
   * Sort direction, 1 for asc, -1 for desc
   */
  sortDir?: number;
  /**
   * Sort language
   */
  sortLang?: 'en'|'fi'|'sv';
  /**
   * Which page to get
   */
  page?: number;
  /**
   * How many products per page
   */
  pageSize?: number;
}

export interface GetProductsByIdListOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  /**
   * Sort results by this
   */
  sortBy?: 'name'|'created_at'|'sku';
  /**
   * Sort direction, 1 for asc, -1 for desc
   */
  sortDir?: number;
  /**
   * Sort language
   */
  sortLang?: 'en'|'fi'|'sv';
}

export interface GetProductsByCategoryOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  /**
   * Sort results by this
   */
  sortBy?: 'name'|'created_at'|'sku';
  /**
   * Sort direction, 1 for asc, -1 for desc
   */
  sortDir?: number;
  /**
   * Sort language
   */
  sortLang?: 'en'|'fi'|'sv';
  /**
   * Which page to get
   */
  page?: number;
  /**
   * How many products per page
   */
  pageSize?: number;
}

export interface GetProductInventoryByIdListOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface UpdateProductInventoryOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    stock_level?: number,
    storage_warning_level?: number,
    storage_warning_level_emails?: string[],
    is_visible?: boolean
  };
}

export interface OneOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface UpdateOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
  body?: {
    type: 'variable'|'print-on-demand'|'stock'|'form'|'editable'|'multiple-option',
    status: 'public'|'unpublished',
    publish_start_at?: string,
    publish_end_at?: string,
    locale: any,
    metadata: any,
    keywords?: any,
    categories?: number[],
    store_id: number
  };
}

export interface GetProductBySkuOptions {
  /**
   * Storefront base URL for context e.g. *mystore.mygrano.fi*
   */
  XStoreIdentifier?: string;
}

export interface CreateOptions {
  body?: api.Product;
}

export interface GetManyOptions {
  /**
   * From what store are we searching, by default the Origin header is used
   */
  XStoreIdentifier?: string;
  /**
   * Filter expression for filtering (https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Layer/#Reading_Data/Filtering)
   */
  filters?: string;
  /**
   * Page number for pagination. First page is 0.
   */
  page?: number;
  /**
   * Page size for pagination. Ignored when used without the page parameter.
   */
  pagesize?: number;
  /**
   * Sort expression (https://js.devexpress.com/Documentation/18_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/sorting/)
   */
  orderby?: string;
}

const getProductsOperation: api.OperationInfo = {
  path: '/product/by-store',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getProductsByIdListOperation: api.OperationInfo = {
  path: '/product/by-store-and-ids',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getProductsByCategoryOperation: api.OperationInfo = {
  path: '/product/by-store-and-category',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getProductInventoryByIdListOperation: api.OperationInfo = {
  path: '/product/inventory/by-store-and-ids',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateProductInventoryOperation: api.OperationInfo = {
  path: '/product/inventory/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const oneOperation: api.OperationInfo = {
  path: '/product/{id}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const updateOperation: api.OperationInfo = {
  path: '/product/{id}',
  contentTypes: ['application/json'],
  method: 'put',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const destroyOperation: api.OperationInfo = {
  path: '/product/{id}',
  method: 'delete',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getProductBySkuOperation: api.OperationInfo = {
  path: '/product/by-sku/{sku}',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const allOperation: api.OperationInfo = {
  path: '/product',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const createOperation: api.OperationInfo = {
  path: '/product',
  contentTypes: ['application/json'],
  method: 'post',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const schemaOperation: api.OperationInfo = {
  path: '/product/schema',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};

const getManyOperation: api.OperationInfo = {
  path: '/v2/product',
  method: 'get',
  security: [
    {
      id: 'apiKeyAuth'
    }
  ]
};
