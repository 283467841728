import { ANONYMOUS_USER, ME } from '@app/api/action/User';
import { UPDATE } from '@app/api/action/Users';
import { ApiAction, RootReducer } from '@app/app.reducers';
import { ApiError } from '@app/shared/utils/api-utils';
import { setName } from '@app/shared/utils/name-helper';
import { LOGOUT, logout } from '@app/user/reducers/auth.reducer';
import { createSelector } from 'reselect';

/** Is given user logged in? */
export function isLoggedUser(user?: api.UserDto | null): user is api.UserDto {
	return typeof user?.id === 'string' && user.id.length > 0;
}

/** Is given user a super admin? */
export function isSuperAdmin(user?: api.UserDto | null): boolean {
	return isLoggedUser(user) && (user?.isSuperAdmin ?? false);
}

/** State update utility. */
function getUserResponseBase(state: State, action: ApiAction<string, api.UserDto>) {
	return {
		hasError: !!action.error,
		user: action.error === true ? state.user : action.payload,
		error: action.error === true ? (action.payload?.body as ApiError) : undefined,
	};
}

// TODO: Convert to use remote data.
export interface State {
	isUserChecked: boolean;
	user?: api.UserDto;
	savedUser?: api.UserDto;
	hasError?: boolean;
	error?: ApiError;
}

export const initialState: State = {
	isUserChecked: false,
};

export const INIT_EMPTY_USER = 'mygrano/user/INIT_EMPTY_USER';
export const initEmptyUser = () => ({ type: INIT_EMPTY_USER }) as const;

export const RESET_USER_CHECKED = 'mygrano/user/RESET_USER_CHECKED';
export const resetUserChecked = () => ({ type: RESET_USER_CHECKED }) as const;

export const RESET_SAVED_USER = 'mygrano/user/RESET_SAVED_USER';
export const resetSavedUser = () => ({ type: RESET_SAVED_USER }) as const;

type UserAction =
	| ReturnType<typeof initEmptyUser | typeof resetUserChecked | typeof resetSavedUser | typeof logout>
	| ApiAction<typeof ME, api.UserDto>
	| ApiAction<typeof ANONYMOUS_USER, api.UserDto>
	| ApiAction<typeof UPDATE, api.UserDto>;

/** User reducer. */
export function reducer(state = initialState, action: UserAction): State {
	switch (action.type) {
		case ME:
		case ANONYMOUS_USER:
			return {
				...state,
				...getUserResponseBase(state, action),
				isUserChecked: true,
			};

		case UPDATE:
			return {
				...state,
				...getUserResponseBase(state, action),
				savedUser: action.error === true ? undefined : action.payload,
			};

		case INIT_EMPTY_USER:
			return {
				...state,
				user: undefined,
				hasError: undefined,
				error: undefined,
				isUserChecked: true,
			};

		case RESET_USER_CHECKED:
			return { ...state, isUserChecked: initialState.isUserChecked };

		case RESET_SAVED_USER:
			return { ...state, savedUser: undefined, hasError: undefined, error: undefined };

		case LOGOUT:
			return { ...initialState, isUserChecked: state.isUserChecked };

		default:
			return state;
	}
}

export const getState = (state: RootReducer.State) => state.user;
export const getIsUserChecked = setName(
	'getIsUserChecked',
	createSelector(getState, (state) => state.isUserChecked),
);
export const getUser = setName(
	'getUser',
	createSelector(getState, (state) => state.user),
);
export const getIsAnonUser = setName(
	'getIsAnonUser',
	createSelector(getState, (state) => state?.user?.isAnonUser),
);
export const getSavedUser = setName(
	'getSavedUser',
	createSelector(getState, (state) => state.savedUser),
);
export const getUserPermissions = setName(
	'getUserPermissions',
	createSelector(getState, (state) => state?.user?.permissions),
);
export const getHasError = setName(
	'getHasError',
	createSelector(getState, (state) => state.hasError),
);
export const getUserError = setName(
	'getUserError',
	createSelector(getState, (state) => state.error),
);
export const getIsLoggedIn = setName(
	'getIsLoggedIn',
	createSelector(getState, (state) => state.isUserChecked && isLoggedUser(state.user)),
);
export const getIsNotLoggedIn = setName(
	'getIsNotLoggedIn',
	createSelector(getState, (state) => !state.isUserChecked || !isLoggedUser(state.user)),
);
export const getIsSuperAdmin = setName(
	'getIsSuperAdmin',
	createSelector(getState, (state) => state.isUserChecked && isSuperAdmin(state.user)),
);
