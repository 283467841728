@if (data.icon) {
	<g-icon class="icon" [icon]="data.icon" fixedWidth size="lg" />
}
<div class="content">
	<div class="title">{{ data.title }}</div>
	@if (data.body) {
		<div class="body">{{ data.body }}</div>
	}
</div>
@if (data.action) {
	<button mat-button class="action mat-button-dismiss" (click)="data.onAction?.()">{{
		data.action
	}}</button>
}
